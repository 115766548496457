<template>
    <div>
        <div class="btn" @click="jumpTest">开始测评</div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {
        jumpTest() {
            this.$router.push('/test')
        }
    }
}
</script>

<style scoped lang="less">
    .btn {
        width: 283px;
        height: 42px;
        margin: 16px auto 26px auto;
        font-size: 18px;
        font-weight: bold;
        background: #99F1B2;
        border-radius: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
